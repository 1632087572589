@value gutter: 36px;
@value spacing: 48px;
@value break-small: 375px;
@value white: #FFFFFF;

.content {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  margin-top: spacing;
}
.container {
  padding: gutter 0 gutter gutter;
  min-height: 100vh;
}
.header {
  display: flex;
  justify-content: center;
}

.header h1 {
  color: white;
  letter-spacing: 1px;
  margin: 0 0 0 5px;
}
.reset {
  display: flex;
  justify-content: center;
}
.reset button {
  width: 300px;
  height: 24px;
  background: white;
  padding: 4px;
  border-radius: 5px;
}
.winner {
  display: flex;
  justify-content: center;
  min-height: 80vh;
  flex-wrap: wrap;
}
.winner h1 {
  margin-top: auto;
  margin-bottom: auto;
  color: white;
  font-size: 72px;
}
@media screen and (max-width: break-small) {
  .reset {
    justify-content: flex-start;
  }
  .header {
    justify-content: flex-start;
  }

  .content {
    justify-content: flex-start;
  }
  .action:nth-of-type(even) {
    margin-right: 0px;
  }
}
